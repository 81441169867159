import {booleanAttribute, computed, effect, inject, Injectable, Signal, signal, WritableSignal} from '@angular/core';
import {catchError, finalize, map, Observable, tap, throwError} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {CryptoKeyInterface, CryptoKeyServiceInstance} from "../instances/crypto-key.instance";
import {StorageKeys} from "../../../storage-keys";
import {User} from "../interfaces/user";
import {BlockUiService} from "./block-ui.service";
import {AbstractService} from "./abstract.service";

interface AuthState {
  sessionKey: any,
  theme: string | null
  logged: boolean
  _w: string; // chave de criptografia
}

@Injectable({
  providedIn: 'root'
})
export class AuthService extends AbstractService {

  /** Instances */
  private _w: CryptoKeyInterface = CryptoKeyServiceInstance();

  /** Signals */
  public logged: WritableSignal<boolean> = signal<boolean>(true);
  public userData: WritableSignal<User> = signal<User>({} as User);


  /** Variaveis */
  private _e: AuthState = {
    sessionKey: null,
    theme: null,
    logged: false,
    _w: null
  }
  constructor() {
    super();
  }

  public isLogged(): Observable<any> {
    return this.http.get(`${this.url}/auth/logged`).pipe(
      map(res => res['data']),
      tap(res => {
        this.userData.set(res)
        this.setAuthState({
            sessionKey: res.sessionKey,
            theme: 'undefined',
            logged: true,
            _w: res._w
          });
      }),
      catchError(error => {
        this.userData.set({} as User);
        this.setAuthState(this._e, );
        return throwError(error);
      })
    );
  }

  public logout(): Observable<any> {
    return this.http.get(`${this.url}/auth/logout`).pipe(
      tap(res => {
        this.userData.set({} as User);
        this.setAuthState(this._e);
      }),
      catchError(error => {
        this.userData.set({} as User);
        this.setAuthState(this._e,);
        return throwError(error);
      })
    );
  }

  public login(params: {email:string, password:string }): Observable<any> {
    return this.http.post(`${this.url}/auth/login`, params).pipe(
      map(res => res['data']),
      tap(res => {
        this.userData.set(res)
        this.setAuthState({
          sessionKey: res.sessionKey,
          theme: 'undefined',
          logged: true,
          _w: res._w
        },);
      }),
      catchError(error => {
        this.userData.set({} as User)
        this.setAuthState(this._e);
        return throwError(error);
      })
    );
  }

  private setAuthState(authData: AuthState): void {
    window.localStorage.setItem(StorageKeys.AUTH_TOKEN, authData.sessionKey);
    window.localStorage.setItem(StorageKeys.THEME, 'true');
    window.localStorage.setItem(StorageKeys.LOGGED, authData.logged.toString());

    this.logged.set(authData.logged);
    this._w.set(authData._w);
  }

  getUserData(): Signal<User> {
    return computed(() => this.userData())
  }
}
