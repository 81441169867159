import { Observable, ReplaySubject } from "rxjs";

export interface CryptoKeyInterface {
  set: (value: string) => void,
  get: () => Observable<string>
}

let CryptoKeyInstance: CryptoKeyInterface| null = null;

export const CryptoKeyServiceInstance = (): CryptoKeyInterface => {
  if (!CryptoKeyInstance) {
    const _value = new ReplaySubject<string>(1);

    const get = () => _value.asObservable();

    const set = (value: string) => {
      _value.next(value);
    }

    CryptoKeyInstance =  {
      get: get,
      set
    };
  }

  return CryptoKeyInstance
};
